

export function removeAllSelected(all_items){
    all_items.forEach(function (temp_item) {
        if(temp_item.classList.contains('selected')) {
            temp_item.classList.remove('selected');
        }
    });
}

export function toggleSelectedItems(all_items, active_item) {
    all_items.forEach(function (temp_item) {
        if(temp_item.classList.contains('selected')) {
            temp_item.classList.remove('selected');
        }
    });
    active_item.classList.add('selected');
}





