import {
    toggleSelectedItems,
    removeAllSelected
} from "./nav_functions";


    const hamburger = document.getElementById("nav-hamburger");
    const expandedTopNavbar = document.getElementById("top-navbar-expanded");
    const topLevelTopNavItems = document.querySelectorAll('.toplevel-top-nav-item');
    const topNavDropdownToggleItems = document.querySelectorAll('.top-nav-dropdown-toggle-item');
    const topNavDropdownItems = document.querySelectorAll('.top-nav-dropdown-item');

    const sidebarMenuHamburger = document.getElementById("sidebar-menu-hamburger");
    const sidebarMenuArrow = document.getElementById("sidebar-menu-arrow");
    const leftSidebarNav = document.getElementById("left-sidebar-nav");

    const topLevelSidebarItems = document.querySelectorAll('.toplevel-sidebar-item');
    const sidebarDropdownToggleItems = document.querySelectorAll('.sidebar-dropdown-toggle-item');
    const sidebarDropdownItems = document.querySelectorAll('.sidebar-dropdown-item');
    const hideMenuItems = document.querySelectorAll('.hide-menu');
    const mainSection = document.getElementById("admin-main");
    let currentExpandedNavbarHeight;
    let defaultNavbarHeight;

    //sidebarNavHideMenuItemNames();
    //sidebarNavToggleMenuNamesOnHover(leftSidebarNav);

    hamburger.addEventListener("click", function (){
        let navIcon = this.firstElementChild.firstElementChild;
        if(navIcon.classList.contains('mdi-menu')){
            topNavOpenMenu();
        } else {
            topNavCloseMenu();
        }
    });

    /*sidebarMenuHamburger.addEventListener("click", function() {
        sidebarNavToggleMenuItemNames();
    });

    sidebarMenuArrow.addEventListener("click", function() {
        sidebarNavToggleMenuItemNames();
    });*/




    topLevelTopNavItems.forEach(function (item) {
        item.addEventListener('click', function () {
            if(item.classList.contains('top-nav-dropdown-toggle-item')) {
                if(item.classList.contains('selected')) {
                    topNavToggleDropdown(item);
                } else {
                    topNavCloseAllDropdowns(topNavDropdownToggleItems, topNavDropdownItems);
                    toggleSelectedItems(topLevelTopNavItems, item);
                    topNavOpenDropdown(item);
                }
            } else {
                if(item.classList.contains('selected')) {
                    topNavCloseAllDropdowns(topNavDropdownToggleItems, topNavDropdownItems);
                } else {
                    topNavCloseAllDropdowns(topNavDropdownToggleItems, topNavDropdownItems);
                    toggleSelectedItems(topLevelTopNavItems, item);
                }
            }
        });
    });

    topNavDropdownItems.forEach(function (item) {
        item.addEventListener('click', function () {
            toggleSelectedItems(topNavDropdownItems, item);
        });
    });




    topLevelSidebarItems.forEach(function (item) {
        item.addEventListener('click', function () {
            if(item.classList.contains('sidebar-dropdown-toggle-item')) {
                if(item.classList.contains('selected')) {
                    sidebarNavToggleDropdown(item);
                } else {
                    sidebarNavCloseAllDropdowns(sidebarDropdownToggleItems, sidebarDropdownItems);
                    toggleSelectedItems(topLevelSidebarItems, item);
                    sidebarNavOpenDropdown(item);
                }
            } else {
                if(item.classList.contains('selected')) {
                    sidebarNavCloseAllDropdowns(sidebarDropdownToggleItems, sidebarDropdownItems);
                } else {
                    sidebarNavCloseAllDropdowns(sidebarDropdownToggleItems, sidebarDropdownItems);
                    toggleSelectedItems(topLevelSidebarItems, item);
                }
            }
        });
    });

    sidebarDropdownItems.forEach(function (item) {
        item.addEventListener('click', function () {
            toggleSelectedItems(sidebarDropdownItems, item);
        });
    });



    window.addEventListener('click', function(e) {
        if (!e.target.closest('#sidebar-menu-hamburger, #sidebar-menu-arrow, #left-sidebar-nav')) {
            sidebarNavCloseAllDropdowns(sidebarDropdownToggleItems, sidebarDropdownItems);
            //sidebarNavHideMenuItemNames();
        }
        if (!e.target.closest('#nav-hamburger, #top-navbar-expanded')) {
            topNavCloseMenu();
        }

        if (!e.target.closest('.top-nav-dropdown-toggle-item')) {
            topNavCloseAllDropdowns(topNavDropdownToggleItems, topNavDropdownItems);
        }
    });



    function topNavOpenMenu() {
        expandedTopNavbar.style.maxHeight = expandedTopNavbar.scrollHeight + "px";
        currentExpandedNavbarHeight = expandedTopNavbar.scrollHeight;
        hamburger.firstElementChild.firstElementChild.classList.replace('mdi-menu', 'mdi-close');
    }

    function topNavCloseMenu() {
        if (expandedTopNavbar.style.maxHeight){
            expandedTopNavbar.style.maxHeight = null;
            currentExpandedNavbarHeight = expandedTopNavbar.scrollHeight;
        }
        hamburger.firstElementChild.firstElementChild.classList.replace('mdi-close', 'mdi-menu');
    }


    function topNavOpenDropdownContent(dropdownContent, dropdown_toggle_item) {
        dropdownContent.style.maxHeight = dropdownContent.scrollHeight + 100 + "px";
        currentExpandedNavbarHeight = expandedTopNavbar.scrollHeight + dropdownContent.scrollHeight;
        expandedTopNavbar.style.maxHeight = currentExpandedNavbarHeight + "px";
        dropdown_toggle_item.querySelector('.mdi-menu-down')
            .classList.replace('mdi-menu-down', 'mdi-menu-up');
        dropdownContent.classList.add('opened');
    }

    function topNavCloseDropdownContent(dropdownContent, dropdown_toggle_item) {
        dropdownContent.style.maxHeight = null;
        currentExpandedNavbarHeight = expandedTopNavbar.scrollHeight + dropdownContent.scrollHeight;
        expandedTopNavbar.style.maxHeight = currentExpandedNavbarHeight + "px";
        dropdown_toggle_item.querySelector('.mdi-menu-up')
            .classList.replace('mdi-menu-up', 'mdi-menu-down');
        if(dropdownContent.classList.contains('opened')) {
            dropdownContent.classList.remove('opened');
        }
    }

    function topNavCloseAllDropdowns(dropdownToggleItemsList, dropdownItemsList) {
        dropdownItemsList.forEach(function (temp_item) {
            if(temp_item.classList.contains('selected')) {
                temp_item.classList.remove('selected');
            }
        });
        dropdownToggleItemsList.forEach(function (item) {
            let dataAttrValue = item.getAttribute('data-display-dropdown');
            const dropdownContent = document.getElementById(dataAttrValue);
            if (dropdownContent.style.maxHeight){
                topNavCloseDropdownContent(dropdownContent, item);
            }
        });
    }

    function topNavOpenDropdown(dropdown_toggle_item) {
        let dataAttrValue = dropdown_toggle_item.getAttribute('data-display-dropdown');
        const dropdownContent = document.getElementById(dataAttrValue);
        topNavOpenDropdownContent(dropdownContent, dropdown_toggle_item);
    }


    function topNavToggleDropdown(dropdown_toggle_item) {
        let dataAttrValue = dropdown_toggle_item.getAttribute('data-display-dropdown');
        const dropdownContent = document.getElementById(dataAttrValue);
        if (dropdownContent.style.maxHeight){
            topNavCloseDropdownContent(dropdownContent, dropdown_toggle_item);
        } else {
            topNavOpenDropdownContent(dropdownContent, dropdown_toggle_item);
        }
    }



    function sidebarNavCloseAllDropdowns(dropdownToggleItemsList, dropdownItemsList) {
        dropdownItemsList.forEach(function (temp_item) {
            if(temp_item.classList.contains('selected')) {
                temp_item.classList.remove('selected');
            }
        });
        dropdownToggleItemsList.forEach(function (item) {
            let dataAttrValue = item.getAttribute('data-display-dropdown');
            const dropdownContent = document.getElementById(dataAttrValue);
            if (dropdownContent.style.maxHeight){
                dropdownContent.style.maxHeight = null;
                item.querySelector('.mdi-menu-up')
                    .classList.replace('mdi-menu-up', 'mdi-menu-down');
            }
        });
    }



    function sidebarNavOpenDropdown(dropdown_toggle_item) {
        let dataAttrValue = dropdown_toggle_item.getAttribute('data-display-dropdown');
        const dropdownContent = document.getElementById(dataAttrValue);
        dropdownContent.style.maxHeight = dropdownContent.scrollHeight + "px";
        dropdown_toggle_item.querySelector('.mdi-menu-down')
            .classList.replace('mdi-menu-down', 'mdi-menu-up');
    }

    function sidebarNavToggleDropdown(dropdown_toggle_item) {
        let dataAttrValue = dropdown_toggle_item.getAttribute('data-display-dropdown');
        const dropdownContent = document.getElementById(dataAttrValue);
        if (dropdownContent.style.maxHeight){
            dropdownContent.style.maxHeight = null;
            dropdown_toggle_item.querySelector('.mdi-menu-up')
                .classList.replace('mdi-menu-up', 'mdi-menu-down');
        } else {
            dropdownContent.style.maxHeight = dropdownContent.scrollHeight + "px";
            dropdown_toggle_item.querySelector('.mdi-menu-down')
                .classList.replace('mdi-menu-down', 'mdi-menu-up');
        }
    }


    /*function sidebarNavHideMenuItemNames() {
        hideMenuItems.forEach(function (item) {
            item.style.display = "none";
        });
        leftSidebarNav.style.width = '70px';
        mainSection.style.paddingLeft = '70px';
        let navArrowIcon = sidebarMenuArrow.firstElementChild.firstElementChild;
        let navSideBurgerIcon = sidebarMenuHamburger.firstElementChild.firstElementChild;
        if(navArrowIcon.classList.contains('mdi-arrow-left-bold') || navSideBurgerIcon.classList.contains('mdi-close')){
            navArrowIcon.classList.replace('mdi-arrow-left-bold', 'mdi-arrow-right-bold');
            navSideBurgerIcon.classList.replace('mdi-close', 'mdi-menu');
        }
    }*/

    function sidebarNavShowMenuItemNames() {
        hideMenuItems.forEach(function (item) {
            item.style.display = "flex";
        });
        leftSidebarNav.style.width = '250px';
        mainSection.style.paddingLeft = '250px';
        let navArrowIcon = sidebarMenuArrow.firstElementChild.firstElementChild;
        let navSideBurgerIcon = sidebarMenuHamburger.firstElementChild.firstElementChild;
        if(navArrowIcon.classList.contains('mdi-arrow-right-bold') || navSideBurgerIcon.classList.contains('mdi-menu')){
            navArrowIcon.classList.replace('mdi-arrow-right-bold', 'mdi-arrow-left-bold');
            navSideBurgerIcon.classList.replace('mdi-menu', 'mdi-close');
        }
    }

    function sidebarNavToggleMenuItemNames() {
        if(leftSidebarNav.style.width === '250px') {
            sidebarNavHideMenuItemNames();
        } else {
            sidebarNavShowMenuItemNames();
        }
    }

    function sidebarNavToggleMenuNamesOnHover(element) {
        element.addEventListener('mouseover', function (){
            sidebarNavShowMenuItemNames();
        });

        element.addEventListener('mouseout', function () {
            sidebarNavHideMenuItemNames();
        });
    }










